<template>
    <div class="px-2 sm:px-10 py-4">
        <Header v-bind="{
            ...content.profile,
            profile: {
                avatar: get(state.currentUser, 'avatar', ''),
                name:
                    get(state.currentUser, 'firstName', '') +
                    ' ' +
                    get(state.currentUser, 'lastName', ''),
                description:
                    'member since ' +
                    moment(
                        get(state.currentUser, 'createdAt', new Date())
                    ).format('M/D/YYYY'),
            },
        }" @onClick="(item) => onClick(item)" @onUpdate="(avatar) => onUpdateAvatar(avatar)" />
        <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
            <div class="col-span-2">
                <Text size="xl" weight="semibold" color="gray-900" :content="'Details'" />
            </div>
            <div class="col-span-5">
                <div class="shadow-lg h-fit rounded-md">
                    <div
                        class="px-5 py-8 grid grid-cols-1 gap-4 sm:grid-cols-2 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
                        <Input v-for="key in Object.keys(content.details)" :key="key" :label="content.details[key].label"
                            v-model:value="content.details[key].value" :type="content.details[key].type" />
                    </div>
                    <div
                        class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
                        <div />
                        <div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
                            <Button content="Cancel" :variant="null"
                                customClass="bg-white text-black underline px-2 bg-gray-100" />
                            <Button @click="() => onSaveDetails()" content="Save" variant="primary" customClass="ml-4 px-4"
                                :isLoading="isLoading" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
            <div class="col-span-2">
                <Text size="xl" weight="semibold" color="gray-900" :content="'Contact Info'" />
            </div>
            <div class="col-span-5">
                <div class="h-fit rounded-md">
                    <div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
                        <div v-for="contact in (content.contacts || []).filter(o => !(o && o.isArchived))" :key="contact.type" class="grid grid-cols-4 py-3 px-2">
                            <div class="col-span-2">
                                <Text size="sm" weight="semibold" color="black" :content="contact.value" />
                            </div>
                            <div class="col-span-1">
                                <span
                                    class="block inline-flex justify-center items-center py-1 bg-gray-500 text-white px-3 rounded-full">
                                    <Icon name="MailIcon" color="white" hover-text-color="white" size="5" class="px-1"
                                        v-if="contact.type === 'Email'" />
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-3 h-3"
                                        v-else-if="contact.type === 'FACEBOOK'">
                                        <path fill="currentColor"
                                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-3 h-3"
                                        v-else-if="contact.type === 'INSTAGRAM'">
                                        <path fill="currentColor"
                                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-3 h-3"
                                        v-else-if="contact.type === 'TWITTER'">
                                        <path fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-3 h-3"
                                        v-else-if="contact.type === 'YOU_TUBE'">
                                        <path fill="currentColor"
                                            d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                                    </svg>

                                    <Text :content="contact.type.toUpperCase()" color="white" size="xs" class="ml-1" />
                                </span>
                            </div>
                            <div class="col-span-1 flex justify-end">
                                <a class="px-1">
                                    <Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900" />
                                </a>
                                <a class="px-1">
                                    <Icon name="TrashIcon" color="red-600" class="hover:text-red-900" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <Text size="sm" color="primary-600" content="+ Add" decoration="underline"
                        custom-class="cursor-pointer mt-2" />
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
            <div class="col-span-2">
                <Text size="xl" weight="semibold" color="gray-900" :content="'Demographics'" />
            </div>
            <div class="col-span-5">
                <div class="shadow-lg h-fit rounded-md">
                    <div
                        class="px-5 py-8 grid grid-cols-1 gap-4 sm:grid-cols-2 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
                        <MultiSelectDropdown v-for="demographic in content.demographics" :key="demographic.label"
                            :class="`w-full col-span-${demographic.cols}`" v-model:selected="demographicData[demographic.label]
                                " :label="demographic.label" :options="demographic.list" is-obj isFull
                            :description="demographic.description" />
                    </div>
                    <div
                        class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
                        <div />
                        <div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
                            <Button content="Cancel" customClass="bg-white text-black underline px-2 bg-gray-100"
                                :variant="null" />
                            <Button content="Save" variant="primary" customClass="ml-4 px-4" :isLoading="isLoading"
                                @click="onSaveDemographic" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
            <div class="col-span-2">
                <Text size="xl" weight="semibold" color="gray-900" :content="'Transactions'" />
            </div>
            <div class="col-span-5">
                <div class="h-fit rounded-md">
                    <div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
                        <div class="grid grid-cols-5 py-3 px-2">
                            <div class="col-span-1 font-semibold">Date</div>
                            <div class="col-span-1 font-semibold">Amount</div>
                            <div class="col-span-1 font-semibold">Status</div>
                            <div class="col-span-2 font-semibold">Note</div>
                        </div>
                        <div v-for="transaction in content.transactions" :key="transaction.id"
                            class="grid grid-cols-5 py-3 px-2">
                            <div class="col-span-1">
                                <Text size="sm" weight="normal" color="black" :content="`${moment(
                                    transaction.createdAt
                                ).format('M/D/YY')}`" />
                            </div>
                            <div class="col-span-1">
                                <Text size="sm" weight="normal" color="black"
                                    :content="getAmount(transaction.amountPaid)" />
                            </div>
                            <div class="col-span-1">
                                <Badge :label="transaction.status" bg-color="gray-500" is-rounded px="3" py="1"
                                    text-color="white" v-if="transaction.status" />
                            </div>
                            <div class="col-span-2">
                                <Text size="sm" weight="normal" color="black" :content="transaction?.note || ''" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/molecules/PageViewHeader/Profile/Profile";
import Text from "../components/atoms/Text/Text";
import Input from "../components/molecules/Inputs/Component/Component";
import Button from "../components/atoms/Button/Button";
import Badge from "../components/molecules/Badge/Basic/Basic";
import Icon from "../components/atoms/Icons/Icons";
import Select from "../components/molecules/Selects/Component/Component.vue";
import MultiSelectDropdown from "@/components/molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";
import { c } from "../components/constants";
import { get, isNumber } from "lodash";
import moment from "moment";
import { formatCurrencyDec, getUploadedFileLink } from "../utils/utils";

export default {
    components: {
        Header,
        Text,
        Input,
        Button,
        Badge,
        Icon,
        Select,
        MultiSelectDropdown,
    },
    data() {
        return {
            content: c.settings,
            get,
            moment,
            isLoading: false,
            demographic: {},
            demographicData: {},
        };
    },
    async mounted() {
        console.log(this.state.currentUser, "currentUser");
        this.content.details.firstName.value =
            this.state.currentUser?.firstName;
        this.content.details.lastName.value = this.state.currentUser?.lastName;
        this.content.details.email.value = this.state.currentUser?.email;
        this.content.contacts = [
            {
                ...this.content.contacts[0],
                value: this.state.currentUser?.email,
            },
        ];
        if (this.state.currentUser?.socialHandles) {
            const socials = this.state.currentUser?.socialHandles?.map(
                (social) => {
                    return {
                        type: social.platform,
                        value: social.handle,
                    };
                }
            );
            this.content.contacts = [...this.content.contacts, ...socials];
        }
        this.content.transactions = this.state.currentUser?.transactions;
        await this.actions.tag.getTags({ first: 10000, where: { status: 'APPROVED' } });
        this.getDemographicData();
    },
    methods: {
        getDemographicData() {
            this.content.demographics[0].list = this.state.tag.tags.filter(
                (t) => t?.category === "AGE"
            );
            this.content.demographics[1].list = this.state.tag.tags.filter(
                (t) => t?.category === "INCOME"
            );
            this.content.demographics[2].list = this.state.tag.tags.filter(
                (t) => t?.category === "GENDER"
            );
            this.content.demographics[3].list = this.state.tag.tags.filter(
                (t) => t?.type === "LOCATION"
            );
            this.content.demographics[4].list = this.state.tag.tags.filter(
                (t) => t?.category === "ETHNICITY"
            );
            this.content.demographics[5].list = [
                ...this.state.tag.tags.filter(
                    (t) => t?.category === "DESCRIPTORS"
                ),
                ...this.state.tag.tags.filter(
                    (t) => t?.category === "DESCRIPTORS"
                ),
            ];
            console.log(this.state.tag.tags);
            this.demographicData.Age = this.state.currentUser?.tags?.filter(
                (t) => t?.category === "AGE"
            );
            this.demographicData.Gender = this.state.currentUser?.tags?.filter(
                (t) => t?.category === "GENDER"
            );
            this.demographicData.Income = this.state.currentUser?.tags?.filter(
                (t) => t?.category === "INCOME"
            );
            this.demographicData.Location =
                this.state.currentUser?.tags?.filter(
                    (t) => t?.type === "LOCATION"
                );
            this.demographicData["Race/Enthicity"] =
                this.state.currentUser?.tags?.filter(
                    (t) => t?.category === "ETHNICITY"
                );
            this.demographicData["Interests and Activities"] = [
                ...this.state.currentUser?.tags.filter(
                    (t) => t?.category === "DESCRIPTORS"
                ),
                ...this.state.currentUser?.tags.filter(
                    (t) => t?.category === "DESCRIPTORS"
                ),
            ];
            console.log(this.demographicData, "data", this.state.currentUser.tags);
        },
        async onSaveDemographic() {
            if (this.isLoading) return false;
            console.log(this.demographicData);
            const origTags = this.state.currentUser?.tags?.filter(
                (t) =>
                    t?.category === "AGE" ||
                    t?.category === "INCOME" ||
                    t?.category === "GENDER" ||
                    t?.type === "LOCATION" ||
                    t?.category === "ETHNICITY" ||
                    t?.category === "DESCRIPTORS" ||
                    t?.category === "DESCRIPTORS"
            );

            const connect = [],
                create = [],
                disconnect = [];
            let allTags = [];
            Object.keys(this.demographicData).map((k) => {
                const items = this.demographicData[k];
                allTags = [...allTags, ...items];
                items.map((item) => {
                    if (item?.id && !origTags?.find((o) => o.id === item?.id)) {
                        connect.push({ id: item?.id });
                    } else if (isNumber(item?.id)) {
                        if (k === "Location") {
                            create.push({
                                name: item?.name,
                                type: "LOCATION",
                            });
                        } else {
                            create.push({
                                name: item?.name,
                                category:
                                    k === "Race/Enthicity"
                                        ? "ETHNICITY"
                                        : k === "Interests and Activity"
                                            ? "DESCRIPTORS"
                                            : k?.toUpperCase(),
                            });
                        }
                    }
                });
            });
            console.log(allTags, "allTags");
            const removeItems = origTags?.filter(
                (o) => !allTags?.find((a) => a?.id === o?.id)
            );
            removeItems?.map((t) => disconnect.push({ id: t.id }));
            this.isLoading = true;
            await this.actions.user.saveUser({
                where: { id: this.state.currentUser?.id },
                data: {
                    tags: {
                        disconnect,
                        create,
                        connect,
                    },
                },
            });
            this.getDemographicData();
            this.isLoading = false;
        },
        async onUpdateAvatar(file) {
            const res = await getUploadedFileLink(file);
            console.log(res, "res");
        },
        getAmount(amount) {
            return formatCurrencyDec(amount);
        },
        onClick(item) {
            console.log(item, "item");
            if (item === "Sign out") {
                this.actions.logout();
                this.actions.alert.showSuccess({
                    message: "Successfully logged out",
                });
                this.$router.push("/login");
            }
        },
        async onSaveDetails() {
            if (this.isLoading) return false;
            try {
                this.isLoading = true;
                const user = await this.actions.user.saveUser({
                    where: { id: this.state.currentUser?.id },
                    data: {
                        firstName: this.content.details.firstName.value,
                        lastName: this.content.details.lastName.value,
                        email: this.content.details.email.value,
                    },
                });
                if (user?.id) {
                    this.actions.alert.showSuccess({
                        message: "Updated profile successfully!",
                    });
                }
                console.log(this.state.currentUser.lastName, "lastName");
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped></style>
